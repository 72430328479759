<template>
    <Modal
        v-model="modal"
        title="选择地理位置"
        :width="600"
        @on-visible-change="visibleChange"
        @on-cancel="submitSelect">
            <Row>
                <Col span="8" style="margin-right: 15px;">
                    <Input v-model="latLng.lng" readonly>
                       <span slot="prepend">经度</span>
                    </Input>
                </Col>
                <Col span="8">
                    <Input v-model="latLng.lat" readonly>
                       <span slot="prepend">纬度</span>
                    </Input>
                </Col>
            </Row>
            <Row v-if="search" style="margin-top: 10px;">
                <Col span="16">
                    <Dropdown @on-click="setSuggestion" :visible="dropMenuVisible" trigger="custom" placement="bottom-start" style="width:100%;">
                        <Input @input="getSuggestions" v-model.trim="suggestion.mapKeyword" placeholder="请输入关键词"></Input>
                        <DropdownMenu slot="list">
                            <DropdownItem v-for="(item,index) in suggestionList" :name="index" :key="index">
                                {{item.title}}({{item.address}})
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </Col>
                <Col span="8">
                    <Button @click="searchMapByKeyword" type="primary">搜索</Button>
                </Col>
            </Row>
            <div :id="mapId" class="custom-map"></div>
            <div slot="footer">
                <Button type="primary" size="large" @click="submitSelect">确定</Button>
            </div>
        </Modal>
</template>
<script>

export default {
    name: 'lookMap',
    props: {
        value: {
            type: Boolean,
            default: false
        },
        longitude: {
            type: [String, Number],
            default: 0
        },
        latitude: {
            type: [String, Number],
            default: 0
        },
        search: {
            type: Boolean,
            default: false
        }
    },
    data(){
        return {
           suggestionList: [],
           dropMenuVisible: false,
           suggestion: {
               mapKeyword: ''
           },
           modal: false,
           latLng: {
               lng: 0,
               lat: 0
           },
           initFlag: false,
           mapId: 'mapId' + new Date().getTime()
        }
    },
    methods: {
        submitSelect(){
            this.modal = false;
            this.$emit('input',false);
            this.$emit('on-select',this.latLng);
        },
        visibleChange(show){
            this.$emit('on-visible-change',show);
        },
        createMap(){
            if(this._map){
                this._map.destroy();
                this._map = null;
            }
            this.$nextTick(()=>{
                this.initMap();
            });
        },
        initMap(){
            if(this.initFlag){
                return ;
            }
            this.initFlag = true;
            if(this._map){
                this._map.setCenter(this.getMapCenter());
                this.addMakerLayer('custom',null,true);
                this.initFlag = false;
            }else {
                let center = this.getMapCenter();
                this._map = new window.TMap.Map(document.getElementById(this.mapId), {
                    center: center,
                    zoom: 16,
                    pitch: 30,
                    rotation: 45
                });
                this._map.on("click",event =>{
                    if(!this.search){
                        return ;
                    }
                    this.latLng = event.latLng;
                    this.addMakerLayer('custom',null,true);
                });
                this.addMakerLayer('custom',null,true);
                this.initFlag = false;
            }
            this.initSearchMap();
        },
        addMakerLayer(id,center,clear){
            if(!this._markerLayers){
                this._markerLayers = {};
            }
            if(clear){
                Object.values(this._markerLayers).forEach((item)=>{
                    item.setMap(null);
                });
                this._markerLayers = {};
            }else {
                let markerLayer = this._markerLayers[id];
                if(markerLayer){
                    markerLayer.setMap(null);
                }
            }
            if(!center){
                center = this.getMapCenter();
            }
            let marker = new window.TMap.MultiMarker({
                id: id,
                map: this._map
            });
            marker.add({
                position: center
            });
            this._markerLayers[id] = marker;
        },
        getMapCenter(){
            let latLng = this.latLng;
            if(!this.search || (latLng.lng > 0 || latLng.lat > 0)){
                return new window.TMap.LatLng(latLng.lat,latLng.lng);
            }else {
                if(!this._ipLocation){
                    this.getIpLocationCenter(true);
                }else {
                    this.latLng = {
                        lng: this._ipLocation.lng,
                        lat: this._ipLocation.lat
                    }
                }
                return this._ipLocation || new window.TMap.LatLng(0, 0);
            }
        },
        getIpLocationCenter(refreshCenter){
            /**let ipLocation = new window.TMap.service.IPLocation();
            ipLocation.locate({}).then((data) => {
                this._ipLocation = data.result.location;
                if(refreshCenter){
                    this.latLng = {
                        lng: this._ipLocation.lng,
                        lat: this._ipLocation.lat
                    }
                    this._map.setCenter(this._ipLocation);
                    this.addMakerLayer('custom',null,true);
                }
            });*/
        },
        initSearchMap(){
            if(!this._searchMap){
                this._searchMap = new window.TMap.service.Search({ pageSize: 10 });
            }
            if(!this._suggestionMap){
                this._suggestionMap = new window.TMap.service.Suggestion({pageSize: 10});
            }
        },
        getSuggestions(){
            let mapKeyword = this.suggestion.mapKeyword;
            if(!mapKeyword){
                return ;
            }
            clearTimeout(this._suggestionTimeout);
            this._suggestionTimeout = setTimeout(() => {
                this._suggestionMap.getSuggestions({ keyword: mapKeyword, location: this._map.getCenter()}).then((result) => {
                    console.log(result);
                    this.suggestionList = result.data || [];
                    this.dropMenuVisible = this.suggestionList.length > 0;
                }).catch((error) => {
                    console.log(error);
                });
            }, 100);
        },
        setSuggestion(event){
            let suggestion = this.suggestionList[event];
            if(suggestion){
                this.suggestion = suggestion;
                this.suggestion.mapKeyword = suggestion.title;
                this.dropMenuVisible = false;

                let map = this._map;
                this.latLng = {
                    lng: suggestion.location.lng,
                    lat: suggestion.location.lat
                }
                map.setCenter(suggestion.location);
                this.addMakerLayer('custom',suggestion.location);
            }
        },
        searchMapByKeyword(){
            let suggestion = this.suggestion;
            let keyword = suggestion.mapKeyword;
            if(keyword){
                this._searchMap.searchRectangle({keyword: keyword,bounds: this._map.getBounds()}).then((result) => {
                    console.log(result);
                    let list = result.data;
                    if(list && list.length > 0){
                        list.forEach((item,index)=>{
                            this.addMakerLayer(index,item.location);
                        });
                        this.dropMenuVisible = false;
                    }
                });
            }
        }
    },
    watch: {
        value: {
            immediate: true,
            handler:function(val){
               this.modal = val;
               if(this.modal){
                   this.createMap();
               }
            }
        },
        longitude: {
            immediate: true,
            handler:function(){
               this.latLng.lng = this.longitude || 0;
               this.latLng.lat = this.latitude || 0;
               if(this.modal){
                  this.createMap();
               }
            }
        }
    }
}
</script>
<style scoped>
.custom-map{
    width: 100%;
    height: 300px;
    margin-top: 10px;
}
</style>